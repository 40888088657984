import { useReactOidc } from '@axa-fr/react-oidc-context';
import { customerExists } from '../../../../api/customer';
import React, { useState } from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
export default function CompanyDetailsForm({ onValidSubmit, initialValues, countries }) {

    const validateCompanyName = (value) => {
        if (value.length < 5) {
            return 'Min length 5 characters'
        }
        return null;
    };
    // const validateOrgNo = (value) => {
    //     if (value.length < 5) {
    //         return 'Min length 5 characters'
    //     }
    //     return null;
    // };
    const validateOrgno = async ({ orgNumber, country }) => {
        // if (!orgNumber) return "Required";
        if (!orgNumber) return;
        var request = { country, orgNumber };
        const response = await customerExists(oidcUser.access_token, request);
        // setCompanyExists(response.exists)

        return response.error;
    }
    const validators = {
        companyName: validateCompanyName,
        orgNumber: validateOrgno,
    };

    const { oidcUser } = useReactOidc();
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                onValidSubmit(values);
            }}
            validate={async values => {
                const errors = {};
                const companyNameError = validators.companyName(values.companyName);
                if (companyNameError) {
                    errors.companyName = companyNameError;
                }
                const orgNoError = await validators.orgNumber(values);
                if (orgNoError) {
                    errors.orgNumber = orgNoError
                }
                return errors;
            }}

        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="mt-2">
                        <label>Company name</label>
                        <input className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
                            value={values.companyName}
                            name="companyName"
                            onBlur={handleBlur}
                            onChange={handleChange}

                        />
                        <div className="text-red-400">
                            {touched.companyName && (errors.companyName)}
                        </div>
                    </div>
                    <div className="mt-2">
                        <label>Organisational number</label>
                        <input type="text" name="orgNumber"
                            value={values.orgNumber}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-gray-300 focus:border-gray-300 sm:text-sm" />
                        <div className="text-red-400">
                            {touched.orgNumber && (errors.orgNumber)}
                        </div>
                        <div className="text-red-400" >
                            {touched.orgNumber && touched.companyName && (errors.comanyNameOrgNo)}
                        </div>
                    </div>
                    <div className="mt-2">
                        <label>Country</label>
                        <select name="country"
                            value={values.country}
                            onChange={e => {
                                handleChange(e)
                            }}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-gray-300 focus:border-gray-300 sm:text-sm">
                            {
                                countries.map(c => {
                                    return <option value={c.code}>{c.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="mt-4 grid grid-cols-2 gap-4">
                        <Link
                            to={'/'}
                            className="text-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-600 bg-brand-button2 hover:bg-brand-button2-hover focus:outline-none">
                            Cancel
                        </Link>
                        <button name="button"
                            type="submit"
                            disabled={isSubmitting}
                            className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-button1 hover:bg-brand-button1-hover focus:outline-none">
                            Next
                            <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </form>)}
        </Formik>
    )
}
